/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Button, Input, Typography
} from 'antd';
import { useSnackbar } from 'notistack';
import Logo from 'static/images/logo-white.svg';
import PageHelmet from 'components/PageHelmet';
import { useDispatch, useSelector } from 'react-redux';
import { FORM_FIELDS } from './constants';
import { signInUser } from './actions';
import { isUserAuthorizedSelector } from './selectors';

const { Title, Paragraph } = Typography;

/**
 * @function
 * @summary Handle user authentication by signing in
 */
const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [submitting, setSubmitStatus] = useState(false);
  const isUserAuthorized = useSelector(isUserAuthorizedSelector);
  const { enqueueSnackbar } = useSnackbar();

  const { resetFields } = form;

  useEffect(() => {
    if (isUserAuthorized) {
      history.push('dashboard');
    }
  }, []);

  const handleSignIn = values => {
    setSubmitStatus(true);

    try {
      dispatch(signInUser({
        enqueueSnackbar, history, setSubmitStatus, values,
      }));
    } catch (error) {
      setSubmitStatus(false);
      resetFields('password');

      enqueueSnackbar(
        'An error has occurred and your sign in request could not be processed.',
        {
          autoHideDuration: 3000,
          variant: 'error',
        }
      );
    }
  };

  return (
    <div className="container-fluid sign-in-body d-flex vh-100 align-items-center">
      <PageHelmet
        pageTitle="Sign in to continue"
        pageDescription="Before being able to access the admin, you need to sign in first"
      />
      <div className="w-100">
        <div className="col-12 px-0 mb-5">
          <div className="col-md-4 col-lg-2 text-center mx-auto px-0">
            <img alt="Softlink Logo" src={Logo} className="img-fluid logo" />
          </div>
        </div>
        <div className="col-md-7 col-lg-4 col-11 mx-auto sign-in-content">
          <Title level={2} className="text-center mb-5">
            Sign In
          </Title>
          <Form
            size="large"
            form={form}
            className="mb-5"
            name="sign-in-form"
            onFinish={handleSignIn}
          >
            {FORM_FIELDS.map(field => {
              const {
                icon, text, key, type, rules,
              } = field;
              return (
                <Form.Item key={key} name={key} rules={rules}>
                  {type === 'password' ? (
                    <Input.Password
                      placeholder={text}
                      prefix={icon}
                      type={type}
                    />
                  ) : (
                    <Input placeholder={text} prefix={icon} type={type} />
                  )}
                </Form.Item>
              );
            })}
            <Button
              htmlType="submit"
              size="large"
              type="primary"
              loading={submitting}
              block
            >
              Authorize Me
            </Button>
          </Form>
          <Paragraph className="text-center">
            <Button
              type="text"
              onClick={() => (window.location = process.env.REACT_APP_USER_APP_URL)}
              className="text-muted"
            >
              If you are not an administrator please click here.
            </Button>
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
